import { MutableRefObject } from 'react';
import { tsvToArray } from '@utils';
import { isEditable } from './constants';

type OnPasteParams = {
  containerRef: MutableRefObject<HTMLTableSectionElement>;
  selectedRow: number;
  selectedCol: number;
  properties: Record<string, any>;
  change: (rows: Record<string, any>) => void;
};

export const onPaste = (e: ClipboardEvent, { containerRef, selectedRow, selectedCol, properties, change }: OnPasteParams) => {
  e.preventDefault();
  const plain = e.clipboardData?.getData('text/plain');
  const rows = tsvToArray(plain);
  const pastedValues = rows.reduce((acc, row, index) => {
    const targetRow = selectedRow + index;
    const targetTr = containerRef.current.querySelector(`[data-rowindex="${targetRow}"]`);
    const rowId = targetTr?.getAttribute('data-rowid');
    return {
      ...acc,
      [rowId]: row.reduce((acc, value, index) => {
        const targetCol = selectedCol + index;
        const targetTd = containerRef.current.querySelector(`[data-rowindex="${targetRow}"][data-colindex="${targetCol}"]`);
        const definitionId = targetTd?.getAttribute('data-definitionid');
        const schema = properties?.[rowId]?.[definitionId];
        if (!schema?.disabled && isEditable(schema)) {
          if (schema?.type === 'string') return { ...acc, [definitionId]: value };
          if (schema?.type === 'number') {
            const number = parseFloat(value);
            if (Number.isNaN(number)) return acc;
            return { ...acc, [definitionId]: number };
          }
          if (schema?.type === 'boolean') return { ...acc, [definitionId]: value === 'TRUE' ? true : value === 'FALSE' ? false : undefined };
        }
        return acc;
      }, {})
    };
  }, {});

  change(pastedValues);
};
