import jwt_decode from 'jwt-decode';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BehaviorSubject, catchError, EMPTY, filter, finalize, mergeMap, Observable, of, Subject, takeUntil, tap, throwError } from 'rxjs';
import { ajax as rxjsAjax } from 'rxjs/ajax';

import {
  APIConnection,
  APIConnectionStatus,
  ApiModel,
  ClientEngineContextValue,
  createUUID,
  getTimeoutError,
  Logger,
  noop,
  TokenSet
} from '@cyferd/client-engine';
import { ToastStatus } from '@components/elements/Toast';
import { Storage } from '@utils';

import { ENV, GENERAL } from '@constants';
import { actions as uiActions } from '../../state-mgmt/ui/actions';
import { logger as defaultLogger } from '@utils';

export type Request = (action: ApiModel.APIAction, reThrow?: boolean) => Observable<ApiModel.APIAction>;

export const tokenSetStorageKey = 'token-set';
const storage = new Storage(localStorage);
/* istanbul ignore next line */
export const tokenStorage = (() => {
  const tokenSet$ = new BehaviorSubject<TokenSet>(null);
  const out$ = tokenSet$.asObservable();
  return {
    set: (tokenResponse: any) => {
      const tokenSet = (() => {
        if (tokenResponse?.access && tokenResponse?.refresh && tokenResponse?.exp) return tokenResponse;
        const exp = jwt_decode<any>(tokenResponse?.access_token)?.exp;
        return { access: tokenResponse?.access_token, refresh: tokenResponse?.refresh_token, exp } as TokenSet;
      })();
      storage.set(tokenSetStorageKey, tokenSet);
      tokenSet$.next(tokenSet);
      return EMPTY;
    },
    get: () => {
      const tokenSet = storage.get(tokenSetStorageKey);
      tokenSet$.next(tokenSet);
      return out$;
    },
    remove: () => storage.remove(tokenSetStorageKey),
    syncGet: () => storage.get(tokenSetStorageKey)
  };
})();

export const WSContext = createContext<ReturnType<ClientEngineContextValue['useWSContext']>>({
  isAuthenticated: false,
  isLoading: false,
  request: null,
  isConnectionReady$: null,
  stream$: null,
  enableConnection: noop
});
export const useWSContext = () => useContext(WSContext);

export type Props = PropsWithChildren<{
  getSocket?: (url: string, headers: any) => WebSocket;
  ajax?: typeof rxjsAjax;
  logger?: Logger;
  avoidSchedulingRefresh?: boolean;
  onVoidSession?: () => void;
  APIConnectionClass?: typeof APIConnection;
}>;

export const WSProvider = ({
  children,
  getSocket,
  ajax = rxjsAjax,
  avoidSchedulingRefresh,
  logger = defaultLogger,
  onVoidSession,
  APIConnectionClass = APIConnection
}: Props) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const client = useMemo(
    () =>
      new APIConnectionClass({
        getSocket,
        getTokenSet: tokenStorage.get,
        setTokenSet: tokenStorage.set,
        logger,
        ajax,
        isConnectionEnabled: !!tokenStorage.syncGet(),
        wsUrl: ENV.WS_URL,
        authUrl: ENV.TOKEN_URL,
        tokenTimeout: ENV.WS_TOKEN_TIMEOUT,
        requestTimeout: ENV.WS_REQUEST_TIMEOUT,
        isClientReady: false,
        maxRetries: ENV.WS_MAX_RETRIES,
        retryDelay: ENV.WS_RETRY_DELAY,
        avoidSchedulingRefresh,
        enableReconnectWithoutPrevConnection: true
      }),
    [APIConnectionClass, ajax, avoidSchedulingRefresh, getSocket, logger]
  );

  const request = useCallback(
    (action: ApiModel.APIAction) => {
      let response;
      return of(null).pipe(
        tap(() => dispatch(uiActions.addLoading(GENERAL.LOADING_KEY.LOAD))),
        mergeMap(() => client.request(action)),
        tap(
          /* istanbul ignore next */ r => {
            if (r?.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA) response = r;
          }
        ),
        tap({
          complete: /* istanbul ignore next */ () => {
            const creationResponse = [ApiModel.TriggerActionType.CORE_UPSERT, ApiModel.TriggerActionType.CORE_CREATE].includes(action.type) &&
              !action?.payload?.record?.createdAt &&
              [response?.payload?.value?.record?.id, response?.payload?.value?.record?.collectionId, response?.payload?.value?.record?.createdAt].every(
                i => !!i && typeof i === 'string'
              ) && { id: response.payload.value.record.id, collectionId: response.payload.value.record.collectionId };

            if (
              [
                ApiModel.TriggerActionType.CORE_UPDATE,
                ApiModel.TriggerActionType.CORE_CREATE,
                ApiModel.TriggerActionType.CORE_UPSERT,
                ApiModel.TriggerActionType.CORE_DELETE
              ].includes(action.type)
            ) {
              dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: 'Success' }, creationResponse));
            }
          }
        }),
        catchError(error => {
          if (!new RegExp(`^${error?.message}`).test(getTimeoutError('').message) && error?.payload?.error?.details?.code !== 'UPDATE_CONFLICT') {
            dispatch(
              uiActions.addToast(
                {
                  id: createUUID(),
                  status: ToastStatus.ERROR,
                  title: error?.payload?.error?.details?.description || 'Oh snap!',
                  message: error?.payload?.error?.details?.message
                },
                { action, error: { ...error, message: error?.message } }
              )
            );
          }
          logger.error(
            ENV.APP_LOGGER_HEADER,
            `Request error: ${
              action?.payload?.query?.cursor?.collectionId
                ? /* istanbul ignore next */ `${action?.type} (${action?.payload?.query?.cursor?.collectionId}) `
                : ''
            }`,
            { title: 'Request error', action, error }
          );
          return throwError(() => error);
        }),
        finalize(() => dispatch(uiActions.removeLoading(GENERAL.LOADING_KEY.LOAD)))
      );
    },
    [dispatch, client, logger]
  );

  useEffect(() => client.setClientReady(isAuthenticated), [client, isAuthenticated]);

  useEffect(() => {
    const streamComplete$ = new Subject<void>();
    client.isConnecting$
      .pipe(
        takeUntil(streamComplete$),
        tap(isConnecting => setLoading(isConnecting))
      )
      .subscribe();

    client.stream$
      .pipe(
        takeUntil(streamComplete$),
        filter(Boolean),
        tap(action => {
          setAuth(true);
          if (/^DISPATCH/.test(action?.type)) dispatch(action);
        })
      )
      .subscribe();

    return () => streamComplete$.next();
  }, [client.isConnecting$, client.stream$, dispatch]);

  useEffect(() => {
    const streamComplete$ = new Subject<void>();
    client.connectionStatus$
      .pipe(
        takeUntil(streamComplete$),
        tap(status => status === APIConnectionStatus.WAITING_TO_RETRY && setLoading(true)),
        tap(status => status === APIConnectionStatus.DEAD && onVoidSession())
      )
      .subscribe();
    return () => streamComplete$.next();
  }, [client.connectionStatus$, isAuthenticated, onVoidSession]);

  useEffect(() => {
    const streamComplete$ = new Subject<void>();
    client.connectionStatus$
      .pipe(
        takeUntil(streamComplete$),
        tap(status => logger.debug(ENV.APP_LOGGER_HEADER, `Connection status: ${status} at ${new Date().toJSON()}`))
      )
      .subscribe();
    return () => streamComplete$.next();
  }, [client.connectionStatus$, logger]);

  return (
    <WSContext.Provider
      value={{
        request,
        isLoading,
        isAuthenticated,
        isConnectionReady$: client.isConnectionReady$,
        stream$: client.stream$,
        enableConnection: client.enableConnection
      }}
    >
      {children}
    </WSContext.Provider>
  );
};
