import { memo, useCallback, useContext, useMemo } from 'react';
import { EMPTY } from 'rxjs';

import { ClientEngineContext, mergeTruthy, useTranslate, ViewModel } from '@cyferd/client-engine';

import { availableComponentList } from '@utils/getComponentChildList';
import { Adder } from '@components/elements/Adder';
import { componentTemplateById, componentTemplates } from './componentTemplates';
import { getNewNode, hasFlag } from '@utils';

export interface ComponentAdderProps {
  eligibleChildList: ViewModel.DisplayName[];
  onSelect: (displayName: ViewModel.Node) => void;
}

export const ComponentAdder = memo(({ eligibleChildList, onSelect }: ComponentAdderProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const { translate } = useTranslate();
  const user = useUserSelector();

  const options = useMemo(() => {
    const isVisible = (name: ViewModel.DisplayName) => {
      const def = componentTemplates[name];
      if (!def) return false;

      if (name === ViewModel.DisplayName.CY_MODAL) return false;
      if (!eligibleChildList.includes(name)) return false;

      const { flag } = def[0].componentConfig || {};
      if (flag) {
        return !!hasFlag(user, flag);
      }
      return true;
    };

    return availableComponentList
      .filter(isVisible)
      .map(displayName => componentTemplates[displayName])
      .flat()
      .map(({ id, item, group }) => ({ id, item, group }));
  }, [eligibleChildList, user]);

  const onInternalSelect = useCallback(
    (event: string) => {
      onSelect(mergeTruthy(getNewNode(componentTemplateById[event].componentConfig.displayName, translate), componentTemplateById[event].template));
      return EMPTY;
    },
    [onSelect, translate]
  );

  return (
    <div data-testid="component-adder-container">
      <Adder onSelect={onInternalSelect} options={options} columnWidth="300px" />
    </div>
  );
});
