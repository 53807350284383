import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import Skeleton from '@components/elements/Skeleton/Skeleton';
import { Switch } from '@components/elements/Switch';
import { TabList } from '@components/elements/TabList';
import { ToolTip } from '@components/elements/Tooltip';
import { UIContext } from '@components/providers/UIprovider';
import { THEME } from '@constants';
import { ErrorBoundary } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { memo, useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CyferdBlack from '../../../../../assets/CyferdLogoBlack.svg';
import CyferdWhite from '../../../../../assets/CyferdLogoWhite.svg';
import { Playground } from '../../../../../builder/views/shared/Playground';
import { NavigationMenuProps } from '../../types';
import AppNavigationWrapper from '../AppNavigationWrapper/AppNavigationWrapper';
import BuilderContentWrapper from '../BuilderContentWrapper/BuilderContentWrapper';
import { styles } from './styles';

enum TabOptions {
  APPS = 'APPS',
  BUILDER = 'BUILDER'
}

const tabOptions = {
  APPS: {
    title: TabOptions.APPS,
    displayName: 'Apps'
  },
  BUILDER: {
    title: TabOptions.BUILDER,
    displayName: 'Builder'
  }
} as const;

export type { NavigationMenuProps };

export const NavigationSkeleton = ({ verticalOffset }: { verticalOffset?: number }) => (
  <div data-testid="navigation-skeleton">
    <div css={[styles.menu(verticalOffset), styles.collapsedMenu]}>
      <div css={styles.loadinSkeleton}>
        <Skeleton.Avatar size={32} />
        <Skeleton.Content round={true} />
        <Skeleton.Content delay={0.3} round={true} />
        <Skeleton.Content delay={0.5} round={true} />
        <Skeleton.Content round={true} />
        <Skeleton.Content delay={0.3} round={true} />
      </div>
    </div>
  </div>
);

export const NavigationMenu = memo(({ isOpen, onOpenChange, user, data = [], isLoading, verticalOffset, recentViews }: NavigationMenuProps) => {
  const { runtimeTheme } = useContext(UIContext);
  const { onDispatchNavigateTo } = useCyActions();
  const { pathname, search } = useLocation();
  const [isPlaygroundOpen, setPlaygoundOpen] = useState(false);
  const [tab, setTab] = useState<TabOptions>(TabOptions.APPS);

  const isAdmin = !!user?.roles?.length;
  const isActive = useCallback((id: string) => (id !== '/' ? pathname.startsWith(id) : pathname === id), [pathname]);
  const isAppActive = useCallback(
    (appId: string) => {
      const id = new URLSearchParams(search).get('id');
      return pathname === '/APP_HOME' && appId === id;
    },
    [pathname, search]
  );

  const handleClick = useCallback(() => onDispatchNavigateTo({ path: '/' }), [onDispatchNavigateTo]);
  const onTogglePlaygoundOpen = () => setPlaygoundOpen(p => !p);
  const onToggleOpen = () => onOpenChange(!isOpen);

  const defaultTabs = [tabOptions.APPS];
  const adminTabs = isAdmin ? [tabOptions.BUILDER] : [];
  const tabList = [...defaultTabs, ...adminTabs];

  const handleTab = (value: boolean) => setTab(value ? TabOptions.BUILDER : TabOptions.APPS);
  const cyferdLogoFallback = runtimeTheme === THEME.DARK ? CyferdWhite : tab === TabOptions.BUILDER ? CyferdWhite : CyferdBlack;

  return (
    <Skeleton loading={isLoading} content={<NavigationSkeleton verticalOffset={verticalOffset} />}>
      <>
        <div css={styles.container}>
          <div
            css={[styles.menu(verticalOffset), !isOpen && styles.collapsedMenu, tab === TabOptions.BUILDER && styles.menuDark]}
            data-testid="main-nav-content"
          >
            <div css={styles.avatarWrapper}>
              <CTA type={CTAType.SEEMLESS} onClick={handleClick} hideLoading={true}>
                <IconImage
                  title=""
                  icon={user?.tenant?.styling?.logoLight || cyferdLogoFallback}
                  iconProps={{ size: '32px' }}
                  imageProps={{ css: styles.imageContent }}
                />
              </CTA>
              <p style={tab === TabOptions.BUILDER ? { color: '#ffffff' } : {}}>{user?.tenant?.name}</p>
            </div>
            {isOpen && tabList.length > 1 && <TabList tabList={tabList} activeTab={tab} onChangeTab={setTab as any} />}
            {!isOpen && tabList.length > 1 && (
              <div css={styles.content}>
                <ToolTip text={`Change to ${tab === TabOptions.BUILDER ? tabOptions.APPS.displayName : tabOptions.BUILDER.displayName}`}>
                  <Switch value={tab === TabOptions.BUILDER} onChange={handleTab} label="" />
                </ToolTip>
              </div>
            )}
            {tab === TabOptions.APPS && (
              <AppNavigationWrapper isOpen={isOpen} handleActive={isAppActive} onToggleOpen={onToggleOpen} data={data} recentViews={recentViews} />
            )}
            {tab === TabOptions.BUILDER && (
              <BuilderContentWrapper isOpen={isOpen} handleActive={isActive} onToggleOpen={onToggleOpen} onTogglePlaygoundOpen={onTogglePlaygoundOpen} />
            )}
          </div>
        </div>
        <ErrorBoundary>
          <Playground open={isPlaygroundOpen} onClose={onTogglePlaygoundOpen} />
        </ErrorBoundary>
      </>
    </Skeleton>
  );
});

export default NavigationMenu;
