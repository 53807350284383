import { ChartDataOutput, ErrorBoundary, ViewModel, useTranslate } from '@cyferd/client-engine';

import { Spinner } from '../Spinner';
import { styles } from './styles';
import { Subscription } from 'rxjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartsAxis,
  ChartsAxisHighlight,
  ChartsClipPath,
  ChartsGrid,
  ChartsTooltip,
  BarElementPath,
  BarPlot,
  LinePlot,
  MarkElement,
  MarkPlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { COLOR, FONT_SIZE, TRANS } from '@constants';
import { ChartLegend } from '../ChartLegend';
import { ClassNames } from '@emotion/react';
import { Header } from '../Header';
import { ChartReferenceLines } from '../ChartReferenceLines';
import { CyText } from '@components/smart/CyText';
import { useGetElementSize } from '@utils';

export type BaseChartProps = {
  type: ViewModel.ChartType.BAR | ViewModel.ChartType.LINE;
  id?: string;
  value?: ChartDataOutput | any;
  isLoading?: boolean;
  testid?: string;
  onClickItem?: (event: any, meta: any) => Subscription;
} & Pick<ViewModel.CyChartProps, 'title' | 'subtitle' | 'config'>;

const CHART_MARGIN = { bottom: 30, top: 30, right: 30 };
const baseTextStyle = { fontFamily: 'inherit', fontSize: FONT_SIZE.XS, fill: COLOR.NEUTRAL_2 };

export const BaseChart = ({ type, id, title, subtitle, value, isLoading, testid, onClickItem }: BaseChartProps) => {
  const [chartAxisKey, setChartAxisKey] = useState(0);
  const currentTheme = useCurrentTheme();
  const { translate } = useTranslate();
  const clipPathId = useMemo(() => `clipPath-${id}`, [id]);
  const { ref: yAxisRef, width: yAxisWidth } = useGetElementSize();
  const chartAxisRef = useCallback(node => node && yAxisRef(node?.querySelector('.MuiChartsAxis-left')), [yAxisRef]);

  const {
    referenceLines,
    dimensions,
    measures,
    metadata: { horizontal, range, applyMask, mask, legendHidden, stacked },
    data
  } = value;

  const safeApplyMask = useMemo(() => (mask && applyMask ? applyMask : undefined), [mask, applyMask]);
  const safeHorizontal = useMemo(() => (type === ViewModel.ChartType.BAR ? (horizontal ?? false) : false), [type, horizontal]);

  const mainAxis = useMemo(
    () =>
      dimensions?.map(({ fieldId }) => ({
        id: fieldId,
        dataKey: fieldId,
        scaleType: 'band',
        min: range?.[0],
        max: range?.[1]
      })),
    [dimensions, range]
  );
  const secondaryAxis = useMemo(
    () => [
      {
        min: range?.[0],
        max: range?.[1],
        valueFormatter: safeApplyMask
      }
    ],
    [range, safeApplyMask]
  );

  const dataset = useMemo(() => data?.map(({ fullItem }) => fullItem), [data]);
  const series = useMemo(
    () =>
      measures?.map(({ baseColor, fieldId, label }) => {
        const color = baseColor && currentTheme?.[baseColor]?.value ? currentTheme?.[baseColor]?.value : undefined;
        return {
          type,
          id: fieldId,
          dataKey: fieldId,
          label: label || fieldId,
          highlightScope: { highlighted: 'series', faded: 'global' },
          valueFormatter: safeApplyMask,
          ...(stacked
            ? {
                stack: 'stack',
                stackOffset: 'none'
              }
            : {}),
          ...(color ? { color } : {}),
          ...(type === ViewModel.ChartType.BAR ? { layout: safeHorizontal ? 'horizontal' : 'vertical' } : {}),
          ...(type === ViewModel.ChartType.LINE
            ? {
                curve: 'catMullrom',
                showMark: true
              }
            : {})
        };
      }),
    [measures, safeHorizontal, currentTheme, safeApplyMask, type, stacked]
  );

  const chartAxis = useMemo<any>(
    () => ({
      x: !safeHorizontal ? mainAxis : secondaryAxis,
      y: !safeHorizontal ? secondaryAxis : mainAxis
    }),
    [safeHorizontal, mainAxis, secondaryAxis]
  );

  const handleClickItem = useCallback((event, d) => onClickItem(data[d?.dataIndex]?.fullItem, { metaKey: event?.metakey }), [data, onClickItem]);
  const shouldRenderChart = useMemo(() => !!data.length && mainAxis?.length && series?.length, [data, mainAxis, series]);

  useEffect(() => {
    setChartAxisKey(prev => prev + 1);
  }, [isLoading, shouldRenderChart, value]);

  const ChartPlot = () => {
    if (type === ViewModel.ChartType.LINE) {
      return (
        <>
          <LinePlot skipAnimation={true} />
          <MarkPlot
            skipAnimation={true}
            onItemClick={onClickItem ? handleClickItem : undefined}
            slots={{
              mark: props => {
                const { dataIndex, color } = props;
                const itemColor = data[dataIndex]?.color;
                return <MarkElement {...props} color={currentTheme?.[itemColor]?.value || color} />;
              }
            }}
          />
        </>
      );
    }
    if (type === ViewModel.ChartType.BAR) {
      return (
        <BarPlot
          skipAnimation={true}
          onItemClick={onClickItem ? handleClickItem : undefined}
          slots={{
            bar: ({ ownerState, ...props }) => {
              const itemColor = data[ownerState.dataIndex]?.color;
              return <BarElementPath {...props} ownerState={{ ...ownerState, color: currentTheme?.[itemColor]?.value || ownerState.color }} />;
            }
          }}
        />
      );
    }
  };

  return (
    <div id={id} data-testid={testid || `${type}-basechart`}>
      <Header title={title} subtitle={subtitle} />
      <>
        {isLoading ? (
          <div data-testid="loading" css={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : shouldRenderChart ? (
          <ErrorBoundary>
            <div css={styles.chartWrapper}>
              <div css={styles.chartContainer}>
                <ResponsiveChartContainer
                  dataset={dataset}
                  series={series as any}
                  xAxis={chartAxis.x}
                  yAxis={chartAxis.y}
                  margin={{ ...CHART_MARGIN, left: Number.isNaN(yAxisWidth) ? 0 : yAxisWidth }}
                >
                  <ChartsGrid horizontal={!safeHorizontal} vertical={safeHorizontal} />
                  <g clipPath={`url(#${clipPathId})`}>
                    <ChartPlot />
                  </g>

                  <g ref={chartAxisRef} key={chartAxisKey}>
                    <ChartsAxis
                      slotProps={{
                        axisLine: { style: { stroke: COLOR.NEUTRAL_3 } },
                        axisTickLabel: { style: baseTextStyle },
                        axisTick: { style: { stroke: COLOR.NEUTRAL_3, opacity: 0.5 } }
                      }}
                      margin={CHART_MARGIN}
                    />
                  </g>
                  <ChartsAxisHighlight />
                  <ClassNames>{({ css, cx }) => <ChartsTooltip classes={{ root: cx('chartTooltip', css(styles.chartTooltip)) }} />}</ClassNames>
                  <ChartReferenceLines referenceLines={referenceLines} forceHorizontal={safeHorizontal} />
                  <ChartsClipPath id={clipPathId} />
                </ResponsiveChartContainer>
              </div>
              <ChartLegend hidden={legendHidden} series={series} referenceLines={referenceLines} testid={testid} />
            </div>
          </ErrorBoundary>
        ) : (
          <div css={styles.empty}>
            <CyText content={translate(TRANS.client.emptyStates.cyList)} titleAlignment={ViewModel.Alignment.CENTER} />
          </div>
        )}
      </>
    </div>
  );
};
