// istanbul ignore file
import { GeneralModel } from '@cyferd/client-engine';
import { tableCellNeedsColor } from '@components/elements/Table/TableCell';
import { ReadonlyFormat } from '@components/elements/ReadonlyFormat';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { IUseTableBodyParams } from '../../types';
import { styles } from './styles';

const makeRow = ({ item, definitionMap, itemIndex, onClick, isLoading }) =>
  item?.list?.map(col => {
    const definition = definitionMap[col.definitionId];
    const isBasicArray = [
      GeneralModel.JSONSchemaFormat.ARRAY,
      GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
      GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
      GeneralModel.JSONSchemaFormat.FILE_LIST
    ].includes(definition?.rawProperty?.format);

    return {
      id: `${itemIndex}-${col.definitionId}`,
      color: !isBasicArray && tableCellNeedsColor(col.value) ? col.color : undefined,
      rowColor: item.color,
      rowBgColor: item.rowColor,
      format: definition.property?.format,
      onClickItem: onClick && (event => onClick(item.fullItem, { metaKey: event?.metaKey, index: itemIndex })),
      rawValue: col.rawValue,
      value: col.value,
      definitionId: col.definitionId,
      fullItem: item.fullItem,
      item: (
        <ReadonlyFormat
          item={{ ...col, color: col.color }}
          definition={definition}
          fullRecord={item.fullItem}
          collectionId={item.fullItem?.collectionId}
          recordId={item.fullItem?.id}
          disabled={!!isLoading}
        />
      )
    };
  });

export const useTableBody = ({ definitionMap, items, onClick, isLoading, hasRecordActions, parseRecordActions, dropdownContainerId }: IUseTableBodyParams) =>
  items.map((item, itemIndex) =>
    [
      ...makeRow({ item, definitionMap, itemIndex, onClick, isLoading }),
      !!hasRecordActions && {
        id: `${itemIndex}-action-list-body-col`,
        item: (
          <div css={styles.actionsContainer}>
            <PreventClickPropagation>
              <OptionMenu defaultBtnType={CTAType.LINK} containerId={dropdownContainerId} optionList={parseRecordActions(item.fullItem, itemIndex)} />
            </PreventClickPropagation>
          </div>
        )
      }
    ].filter(Boolean)
  );
