// istanbul ignore file
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ViewModel } from '@cyferd/client-engine';
import { TableHead } from '@components/elements/Table/TableHead';
import { useTableHeader } from '../../hooks';
import { EditTableCell } from './EditTableCell';
import { onCopy } from './onCopy';
import { onPaste } from './onPaste';
import { onReset } from './onReset';
import { styles } from './styles';
import { useNavigableGrid } from './useNavigableGrid';
import { useParsers } from '@utils';
import { rowId$ } from './constants';
import { COLOR } from '@constants';

export type EditTableProps = {
  list: ViewModel.CyTableProps['value']['list'];
  query: ViewModel.CyTableProps['value']['query'];
  diffs: Record<string, any>;
  errors: Record<string, any>;
  properties: Record<string, any>;
  change: (rows: Record<string, any>) => void;
};

export const EditTable = ({ list, query, diffs, errors, properties, change }: EditTableProps) => {
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const { parseList } = useParsers();

  const { head, items, definitionMap } = useMemo(() => parseList({ entity: query, list }), [parseList, list, query]);

  const tableHeaderItems = useTableHeader({ head, definitionMap, hideSorting: true, showCalculation: true });

  const { selected, active, isInSelection, ...gridControls } = useNavigableGrid({
    bounds: { rows: items.length, cols: items[0]?.list.length },
    containerRef: bodyRef
  });

  useEffect(() => {
    document.addEventListener('copy', onCopy);
    return () => document.removeEventListener('copy', onCopy);
  }, []);

  const handleOnPaste = useCallback(
    e => onPaste(e, { containerRef: bodyRef, change, properties, selectedCol: selected?.col, selectedRow: selected?.row }),
    [change, properties, selected?.col, selected?.row]
  );

  useEffect(() => {
    document.addEventListener('paste', handleOnPaste);
    return () => document.removeEventListener('paste', handleOnPaste);
  }, [handleOnPaste]);

  // TODO: fix schemas
  const handleOnReset = useCallback(e => onReset(e, { change, properties }), [change, properties]);

  return (
    <table css={styles.table}>
      <TableHead id="editTable-head" testid="editTable-head" head={tableHeaderItems} lastColumnSticky={false} />
      <tbody ref={bodyRef}>
        {items?.map((row, index) => {
          const rowId = row.raw?.[rowId$];
          return (
            <EditTableRow
              key={`editTableRow-${index}`}
              row={row}
              index={index}
              gridControls={gridControls}
              diffs={diffs[rowId]}
              errors={errors[rowId]}
              properties={properties[rowId]}
              change={change}
              definitionMap={definitionMap}
              selected={selected}
              active={active}
              handleOnPaste={handleOnPaste}
              handleOnReset={handleOnReset}
              isInSelection={isInSelection}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const EditTableRow = ({
  row,
  index: rowIndex,
  errors,
  diffs,
  change,
  properties,
  selected,
  isInSelection,
  handleOnReset,
  handleOnPaste,
  active,
  gridControls,
  definitionMap
}) => {
  const rowId = useMemo(() => row.raw?.[rowId$], [row.raw]);
  const rowHasSelected = useMemo(() => selected?.row === rowIndex, [selected, rowIndex]);
  const rowColor = useMemo(() => COLOR[properties?.recordColor?.value], [properties?.recordColor?.value]);

  return (
    <tr data-testid="table-row" key={`edittablerow-${rowId}`} aria-rowindex={rowIndex} css={styles.row}>
      <td css={styles.recordColor(rowColor)} />
      {row?.list?.map(({ definitionId }, colIndex) => {
        const isSelected = rowHasSelected && selected?.col === colIndex;
        const props = properties?.[definitionId] || definitionMap[definitionId];
        const error = errors?.[definitionId];
        const edited = diffs?.[definitionId] !== undefined;
        return (
          <EditTableCell
            key={`edittablecell-${rowIndex}-${colIndex}`}
            rowIndex={rowIndex}
            colIndex={colIndex}
            rowId={rowId}
            format={props.format}
            value={props.value}
            maskedValue={props.maskedValue}
            editable={props.editable}
            options={props.optionList}
            color={props.color}
            icon={props.icon}
            definitionId={definitionId}
            definition={definitionMap[definitionId]}
            error={error}
            edited={edited}
            selected={isSelected}
            inSelection={isInSelection(rowIndex, colIndex)}
            editing={isSelected && active}
            onChange={change}
            onReset={handleOnReset}
            onPaste={handleOnPaste}
            {...gridControls}
          />
        );
      })}
    </tr>
  );
};
