import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { ApiModel, ClientEngineContext, ErrorBoundary, GeneralModel, ofType, swallowError, useUnmountObservable, ViewModel } from '@cyferd/client-engine';

import { CTA, CTAType } from '@components/elements/CTA';
import { GlobalToolbar } from '@components/elements/GlobalToolbar';
import { MainNavigation } from '@components/elements/MainNavigation';
import { Search, SearchProps } from '@components/elements/Search';
import { Banner } from '@components/platform/Banner/Banner';
import { UIContext } from '@components/providers/UIprovider';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { GLOBAL_HEADER_SETTINGS_KEY, TRANS } from '@constants';
import { hasFlag, Storage, useCyActions, useGetElementSize } from '@utils';
import { combineLatest, finalize, take, takeUntil, tap } from 'rxjs';
import { NavigationMenu } from './components/NavigationMenu';
import { Print } from './Print';
import { Shortlink } from './Shortlink';
import { styles } from './styles';
import { INavigationList } from './types';

export type GlobalHeaderProps = PropsWithChildren<{
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenApps?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}>;

export const GlobalHeader = (props: GlobalHeaderProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();

  // TODO: remove and cleanup all legacy when complete
  if (!hasFlag(user, 'EARLY_ACCESS')) return <GlobalHeaderLegacy {...props} />;
  return <GlobalHeaderCompo {...props} />;
};

type State = {
  isLeftPanelOpen: boolean;
  apps: INavigationList;
  recentViews: any[];
  isLoading: boolean;
};

type Action =
  | { type: 'TOGGLE_LEFT_PANEL' }
  | { type: 'SET_APPS'; payload: any[] }
  | { type: 'SET_RECENT_VIEWS'; payload: any[] }
  | { type: 'SET_LOADING'; payload: boolean };

const initialState: State = {
  isLeftPanelOpen: false,
  apps: [],
  recentViews: [],
  isLoading: true
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'TOGGLE_LEFT_PANEL':
      return { ...state, isLeftPanelOpen: !state.isLeftPanelOpen };
    case 'SET_APPS':
      return { ...state, apps: action.payload };
    case 'SET_RECENT_VIEWS':
      return { ...state, recentViews: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export const GlobalHeaderCompo = ({
  children,
  hidden,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenApps,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint
}: GlobalHeaderProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const [{ isLeftPanelOpen, isLoading, apps, recentViews }, dispatch] = useReducer(reducer, initialState);
  const onDestroy$ = useUnmountObservable();
  const user = useUserSelector();
  const { onDispatchNavigateTo, onFlowRun } = useCyActions();
  const isAdmin = !!user?.roles?.length;
  const { size } = useContext(UIContext);
  const banner = user?.tenant?.banner;
  const bannerTop = banner?.position === 'top';
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const toggleLeftPanel = () => {
    dispatch({ type: 'TOGGLE_LEFT_PANEL' });
  };

  const renderBanner = useCallback(() => {
    return <Banner banner={banner} ref={bannerRef} />;
  }, [banner, bannerRef]);

  useEffect(() => {
    const apps$ = onFlowRun({ id: 'NAVIGATION.APPS' }).pipe(
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      take(1),
      tap(({ list }) => dispatch({ type: 'SET_APPS', payload: list })),
      swallowError()
    );

    const recentViews$ = onFlowRun({ id: 'NAVIGATION.RECENT_VIEWS' }).pipe(
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      take(1),
      tap(({ list }) => dispatch({ type: 'SET_RECENT_VIEWS', payload: list })),
      swallowError()
    );

    combineLatest([apps$, recentViews$])
      .pipe(
        takeUntil(onDestroy$),
        finalize(() => dispatch({ type: 'SET_LOADING', payload: false }))
      )
      .subscribe();
  }, [onFlowRun, onDestroy$]);

  return (
    <>
      {!hidden && (
        <>
          {bannerTop && renderBanner()}
          <header css={styles.header} data-testid="global-header" data-no-print={true} style={{ top: bannerTop ? bannerHeight : 0 }}>
            <div css={styles.container}>
              <div css={styles.section}>
                <NavigationMenu
                  isLoading={isLoading}
                  data={apps}
                  user={user}
                  isOpen={isLeftPanelOpen}
                  recentViews={recentViews}
                  onOpenChange={toggleLeftPanel}
                  verticalOffset={bannerHeight}
                />

                <CTA
                  testid="home-btn"
                  outlined={true}
                  icon="home"
                  type={CTAType.LINK}
                  size={ViewModel.CTASize.LARGE}
                  color="NEUTRAL_1"
                  tooltip={TRANS.client.nav.builder.home}
                  onClick={event => onDispatchNavigateTo({ path: '/' }, event)}
                />
              </div>
              <div css={[styles.section, styles.searchSection]}>
                <ErrorBoundary>
                  {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
                </ErrorBoundary>
              </div>

              <div css={styles.section}>
                {size === GeneralModel.AppSize.L && (
                  <>
                    <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
                    <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
                    <div css={styles.division} />
                  </>
                )}
                <GlobalToolbar
                  verticalOffset={bannerHeight}
                  hiddenFavorites={hiddenFavorites}
                  hiddenApps={hiddenApps}
                  hiddenNotifications={hiddenNotifications}
                  hiddenPreferences={hiddenPreferences}
                  hiddenShortlink={hiddenShortlink}
                  hiddenPrint={hiddenPrint}
                />
              </div>
            </div>
          </header>
          <div css={styles.placeholder}></div>
        </>
      )}
      <div
        css={[isAdmin && styles.pinnedMainNav, isAdmin && isLeftPanelOpen && styles.pinnedOpenMainNav]}
        style={{ paddingBottom: bannerTop ? 0 : bannerHeight, paddingTop: bannerTop ? bannerHeight : 0 }}
      >
        {children}
      </div>
      {!bannerTop && renderBanner()}
    </>
  );
};

export const GlobalHeaderLegacy = ({
  children,
  hidden,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenApps,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint,
  storage = new Storage()
}: GlobalHeaderProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const initialPinned: boolean = useMemo(() => storage.get(GLOBAL_HEADER_SETTINGS_KEY), [storage]);
  const [isPinned, setPinned] = useState<boolean>(!!initialPinned);
  const [isLeftPanelOpen, setLeftPanelOpen] = useState<boolean>(false);
  const user = useUserSelector();
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');
  const isAdmin = !!user?.roles?.length;
  const { size } = useContext(UIContext);
  const banner = user?.tenant?.banner;
  const bannerTop = banner?.position === 'top';
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const onChangePinned = useCallback(() => {
    storage.set(GLOBAL_HEADER_SETTINGS_KEY, !isPinned);
    setPinned(!isPinned);
    setLeftPanelOpen(true);
  }, [isPinned, storage]);

  const renderBanner = useCallback(() => {
    return <Banner banner={banner} ref={bannerRef} />;
  }, [banner, bannerRef]);

  return (
    <>
      {!hidden && (
        <>
          {bannerTop && renderBanner()}
          <header css={styles.header} data-testid="global-header" data-no-print={true} style={{ top: bannerTop ? bannerHeight : 0 }}>
            <div css={styles.container}>
              <div css={styles.section}>
                {isAdmin && (
                  <MainNavigation
                    verticalOffset={bannerHeight}
                    isPinned={isPinned}
                    isOpen={isLeftPanelOpen}
                    onChangePinned={onChangePinned}
                    onOpenChange={setLeftPanelOpen}
                  />
                )}
                <CTA
                  testid="home-btn"
                  outlined={true}
                  icon="home"
                  type={CTAType.LINK}
                  size={ViewModel.CTASize.LARGE}
                  color="NEUTRAL_1"
                  tooltip={TRANS.client.nav.builder.home}
                  onClick={event => onNavigateTo({ path: '/' }, event)}
                />
              </div>
              <div css={[styles.section, styles.searchSection]}>
                <ErrorBoundary>
                  {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
                </ErrorBoundary>
              </div>

              <div css={styles.section}>
                {size === GeneralModel.AppSize.L && (
                  <>
                    <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
                    <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
                    <div css={styles.division} />
                  </>
                )}
                <GlobalToolbar
                  verticalOffset={bannerHeight}
                  hiddenFavorites={hiddenFavorites}
                  hiddenApps={hiddenApps}
                  hiddenNotifications={hiddenNotifications}
                  hiddenPreferences={hiddenPreferences}
                  hiddenShortlink={hiddenShortlink}
                  hiddenPrint={hiddenPrint}
                />
              </div>
            </div>
          </header>
          <div css={styles.placeholder}></div>
        </>
      )}
      <div
        css={[isAdmin && isPinned && styles.pinnedMainNav, isAdmin && isPinned && isLeftPanelOpen && styles.pinnedOpenMainNav]}
        style={{ paddingBottom: bannerTop ? 0 : bannerHeight, paddingTop: bannerTop ? bannerHeight : 0 }}
      >
        {children}
      </div>
      {!bannerTop && renderBanner()}
    </>
  );
};

GlobalHeader.displayName = 'GlobalHeader';
