import { CTA, CTAType } from '@components/elements/CTA';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { BUILDER_ICON, BUILDER_ROUTE, SECTION_NAME, TRANS } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { useCanAccessRoute, useCyActions } from '@utils';
import { IRenderRow } from '../../types';
import { styles } from './styles';

interface IBuilderContentProps {
  isOpen: boolean;
  handleActive: (id: string) => boolean;
  onToggleOpen: () => void;
  onTogglePlaygoundOpen: () => void;
}

const BuilderContentWrapper = ({ isOpen, handleActive, onToggleOpen, onTogglePlaygoundOpen }: IBuilderContentProps) => {
  const { onDispatchNavigateTo } = useCyActions();
  const canAccess = useCanAccessRoute();
  const getNavigateTo =
    (payload: Parameters<typeof onDispatchNavigateTo>[0], eventOverride?: Parameters<typeof onDispatchNavigateTo>) =>
    (event: Parameters<typeof onDispatchNavigateTo>) => {
      return onDispatchNavigateTo(payload, { ...event, ...eventOverride });
    };
  const data = [
    { id: '/ADMIN', icon: 'home_repair_service', label: TRANS.client.nav.builder.builder, onClick: getNavigateTo({ path: '/ADMIN' }) },
    ...[
      {
        id: BUILDER_ROUTE.TAG_EDITOR.ROOT,
        icon: BUILDER_ICON.APPS,
        label: SECTION_NAME.APPS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.TAG_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.ENTITY_EDITOR.ROOT,
        icon: BUILDER_ICON.DATA,
        label: SECTION_NAME.DATA,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.ENTITY_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.FLOW_EDITOR.ROOT,
        icon: BUILDER_ICON.FLOWS,
        label: SECTION_NAME.FLOWS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.FLOW_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.VIEW_EDITOR.ROOT,
        icon: BUILDER_ICON.VIEWS,
        label: SECTION_NAME.VIEWS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.VIEW_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT,
        icon: BUILDER_ICON.INTEGRATIONS,
        label: SECTION_NAME.INTEGRATIONS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.DEVICE_MANAGER.ROOT,
        icon: BUILDER_ICON.DEVICE_MANAGER,
        label: SECTION_NAME.DEVICE_MANAGER,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.DEVICE_MANAGER.ROOT })
      },
      {
        id: BUILDER_ROUTE.INVITATION_EDITOR.ROOT,
        icon: BUILDER_ICON.INVITATIONS,
        label: SECTION_NAME.INVITATIONS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.INVITATION_EDITOR.ROOT })
      },
      {
        id: BUILDER_ROUTE.USER_EDITOR.ROOT,
        icon: BUILDER_ICON.USERS,
        label: SECTION_NAME.USERS,
        onClick: getNavigateTo({ path: BUILDER_ROUTE.USER_EDITOR.ROOT })
      }
    ].filter(({ id }) => canAccess(id))
  ] as Parameters<typeof RenderRow>[0][];

  return (
    <>
      <div css={[styles.content, styles.mainContent]}>
        {data.map(props => (
          <RenderRow {...props} key={props.id} isOpen={isOpen} isActive={handleActive(props.id)} />
        ))}
      </div>
      <div css={styles.content}>
        <div css={styles.divider} />
        <RenderRow id="playground" icon="terminal" label={TRANS.client.nav.builder.playground} onClick={onTogglePlaygoundOpen} isOpen={isOpen} />
        <RenderRow
          id="docs"
          icon={BUILDER_ICON.HELP}
          label={TRANS.client.nav.builder.guide}
          onClick={getNavigateTo({ path: BUILDER_ROUTE.GUIDE.ROOT }, { metaKey: true } as any)}
          isOpen={isOpen}
        />
        <div css={styles.divider} />
        <RenderRow
          id="toogle"
          icon={isOpen ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}
          label=""
          onClick={onToggleOpen}
          isOpen={isOpen}
          isToogleButton={true}
        />
      </div>
    </>
  );
};

export default BuilderContentWrapper;

const RenderRow = ({ icon, label, onClick, isToogleButton = false, isActive = false, isOpen }: IRenderRow) => {
  return (
    <div css={styles.option} key={label}>
      <PreventClickPropagation containerCss={styles.optionPrevent}>
        <div
          css={[styles.optionContent, isActive && styles.activeOptionContent, isToogleButton && { justifyContent: 'flex-end' }, !isOpen && styles.collapsed]}
          onClick={onClick}
        >
          <CTA
            testid={label}
            outlined={true}
            icon={icon}
            label={!!isOpen && label}
            tooltip={label}
            type={CTAType.LINK}
            size={ViewModel.CTASize.LARGE}
            color={isActive ? 'BASE_BACKGROUND' : 'ABSOLUTE_WHITE'}
          />
        </div>
      </PreventClickPropagation>
    </div>
  );
};
