import { CTAType } from '@components/elements/CTA';
import { MenuOption, OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { TRANS } from '@constants';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useCallback } from 'react';
import { INavigationItem } from '../../types';
import { styles } from './styles';

export interface SubItemMenuProps extends Pick<INavigationItem, 'collections' | 'views'> {
  testid?: string;
  viewActiveId?: string;
  verticalOffset?: number;
  horizontalOffset?: number;
  collectionActiveId?: string;
}

interface ISubItemLabel {
  label: string;
}

interface ISubItemValue {
  title: string;
  isActive: boolean;
  verticalOffset?: number;
  horizontalOffset?: number;
  optionList?: MenuOption[];
  onClick: () => void;
}

const SubItemLabel = ({ label }: ISubItemLabel) => (
  <li css={[styles.item, styles.title]}>
    <span css={styles.label}>{label}</span>
  </li>
);
const SubItemValue = ({ onClick, isActive, title, horizontalOffset, verticalOffset, optionList }: ISubItemValue) => (
  <li onClick={onClick} css={[styles.item, styles.subtitle, isActive && styles.activeOptionContent]}>
    <span css={styles.label}>{title}</span>
    <div css={styles.actionContainer} data-selector="subItemMenu-actionContainer">
      <PreventClickPropagation>
        <OptionMenu
          horizontal={horizontalOffset}
          vertical={verticalOffset}
          defaultBtnType={CTAType.ACTION_TERTIARY}
          defaultBtnSize={ViewModel.CTASize.SMALL}
          optionList={optionList}
        />
      </PreventClickPropagation>
    </div>
  </li>
);

export const SubItemMenu = ({ collections = [], views = [], testid, viewActiveId, verticalOffset, collectionActiveId, horizontalOffset }: SubItemMenuProps) => {
  const { onDispatchFormModal, onDispatchNavigateTo } = useCyActions();
  const onCreateNew = useCallback(
    (collectionId: string) => (event?: any) =>
      onDispatchFormModal(
        {
          type: ApiModel.ApiEntity.ENTITY,
          title: TRANS.client.buttons.createNew,
          formType: ViewModel.CyFormType.STEPPER,
          collectionId
        },
        event
      ),
    [onDispatchFormModal]
  );
  const collectionNavigate = useCallback(
    (collectionId: string) => () => onDispatchNavigateTo({ path: `/TABLE?collectionId=${collectionId}` }),
    [onDispatchNavigateTo]
  );
  const viewNavigate = useCallback((viewId: string) => () => onDispatchNavigateTo({ path: `/${viewId}` }), [onDispatchNavigateTo]);

  return (
    <ul data-testid={testid || 'SubItemMenu'} css={styles.container}>
      {views.length > 0 && (
        <>
          <SubItemLabel label="VIEWS" />
          {views.map(data => (
            <SubItemValue onClick={viewNavigate(data.id)} isActive={viewActiveId === data.id} key={data.id} title={data.recordTitle} />
          ))}
        </>
      )}
      {collections.length > 0 && (
        <>
          <SubItemLabel label="DATA COLLECTIONS" />
          {collections.map(data => (
            <SubItemValue
              onClick={collectionNavigate(data.id)}
              isActive={collectionActiveId === data.id}
              title={data.recordTitle}
              key={data.id}
              optionList={[{ label: TRANS.client.buttons.createNew, image: 'add', onClick: onCreateNew(data.id) }]}
              horizontalOffset={horizontalOffset}
              verticalOffset={verticalOffset}
            />
          ))}
        </>
      )}
    </ul>
  );
};

SubItemMenu.Label = SubItemLabel;
SubItemMenu.Value = SubItemValue;
