// istanbul ignore file
import { isObject } from 'lodash';
import { useContext, useMemo } from 'react';
import { GeneralModel } from '@cyferd/client-engine';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { IUseQuickFiltersParams } from '../../../types';

export const useQuickFilters = ({ value, header: { hideQuickFilters } = {} }: IUseQuickFiltersParams) => {
  const { useParsers } = useContext(CyWrapperContext);

  const { parseData } = useParsers();

  const quickFilters = useMemo(
    () => !hideQuickFilters && (value?.query?.quickFilters?.filter(f => isObject(f) && !parseData(f.hidden)) as GeneralModel.FetchCriteria['quickFilters']),
    [parseData, value?.query?.quickFilters, hideQuickFilters]
  );

  return {
    quickFilters,
    hasQuickFilters: !!quickFilters?.length
  };
};
