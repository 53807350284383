import { useId } from 'react';
import { Table } from '@components/elements/Table';
import { ReadOnlyTableProps } from '@components/smart/CyTable/types';
import { useTableBody, useTableHeader } from '../../hooks/useTable';
import { useOnClick } from './useOnClick';
import { useRecordActions } from './useRecordActions';

export const ReadOnlyTable = ({ refreshId, value, definitionMap, items, isLoading, hideSorting, head, rowActions, updateCursor }: ReadOnlyTableProps) => {
  const dropdownContainerId = useId();

  const { parseRecordActions, hasRecordActions } = useRecordActions({
    value,
    isLoading,
    rowActions
  });
  const { onClick } = useOnClick({ rowActions, isLoading, refreshId });

  const tableBody = useTableBody({
    items,
    isLoading,
    definitionMap,
    dropdownContainerId,
    schemaProperties: value.query?.schema?.properties,
    onClick,
    hasRecordActions,
    parseRecordActions
  });
  const tableHeaderItems = useTableHeader({
    head,
    cursor: value.query?.cursor,
    definitionMap,
    hideSorting,
    updateCursor,
    isLoading,
    hasActions: hasRecordActions
  });
  return (
    <div>
      <Table head={tableHeaderItems} body={tableBody} lastColumnSticky={!!hasRecordActions} />
      <div id={dropdownContainerId} />
    </div>
  );
};
