import { useCyActions } from '@utils';
import { useCallback } from 'react';
import { ItemMenu } from '../ItemMenu';
import { ItemMenuPopover } from '../ItemMenuPopover';
import { SubItemMenu } from '../SubItemMenu';
import { styles } from './styles';

interface IRecentViewsList {
  recentViews: any[];
}

interface IRecentViews extends IRecentViewsList {
  isOpen: boolean;
  onClick: () => void;
  isShowMore: boolean;
}

export const RecentViews = ({ recentViews, onClick, isOpen, isShowMore }: IRecentViews) => (
  <>
    <div css={styles.divider} />
    <ItemMenuPopover disabled={isOpen} content={<RecentViewsList recentViews={recentViews} />}>
      <ItemMenu
        onClick={onClick}
        optionList={[]}
        containChildren={true}
        isActive={false}
        recordTitle="Recent views"
        isShowMore={isShowMore}
        recordImage="history"
        isOpen={isOpen}
        onToggle={onClick}
        id={''}
      />
    </ItemMenuPopover>
    <div css={isOpen && isShowMore ? styles.show : styles.hide}>
      <RecentViewsList recentViews={recentViews} />
    </div>
  </>
);

const RecentViewsList = ({ recentViews }: IRecentViewsList) => {
  const { onDispatchNavigateTo } = useCyActions();
  const collectionNavigate = useCallback(
    (collectionId: string) => onDispatchNavigateTo({ path: `/TABLE?collectionId=${collectionId}` }),
    [onDispatchNavigateTo]
  );
  const viewNavigate = useCallback((viewId: string) => onDispatchNavigateTo({ path: `/${viewId}` }), [onDispatchNavigateTo]);
  const appNavigate = useCallback((appId: string) => onDispatchNavigateTo({ path: `/APP_HOME?id=${appId}` }), [onDispatchNavigateTo]);
  const handleClick = useCallback(
    (collectionId: string, id: string) => () =>
      collectionId === 'App' ? appNavigate(id) : collectionId === 'View' ? viewNavigate(id) : collectionNavigate(id),
    [viewNavigate, appNavigate, collectionNavigate]
  );
  return recentViews
    .filter(view => view?.navigationInfo?.collectionId)
    .map(view => {
      const { id, recordTitle, collectionId } = view?.navigationInfo;
      return <SubItemMenu.Value key={view.id} title={recordTitle} isActive={false} onClick={handleClick(collectionId, id)} />;
    });
};
