import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, GLOBAL_HEADER_HEIGHT, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const MENU_WIDTH = '300px';
export const CLOSED_MENU_WIDTH = '50px';

export const styles = {
  container: css`
    position: relative;
    [data-selector='cta-label'] {
      text-decoration: none;
      font-weight: ${FONT_WEIGHT.NORMAL};
      font-size: ${FONT_SIZE.S};
      margin-left: ${GAP.M};
    }
  `,
  loadinSkeleton: css`
    gap: ${GAP.M};
    display: flex;
    flex-direction: column;
    max-width: 48px;
    margin: 0 auto;
    height: 250px;
  `,
  menu: (verticalOffset?: number) => css`
    position: fixed;
    top: calc(${GLOBAL_HEADER_HEIGHT} + ${verticalOffset || 0}px);
    left: 0;
    z-index: 10;
    width: ${MENU_WIDTH};
    max-width: 100%;
    background-color: ${COLOR.NEUTRAL_5};
    padding-top: ${GAP.XS};
    padding-bottom: ${GAP.XS};
    border-top-right-radius: ${RADIUS.S};
    border-bottom-right-radius: ${RADIUS.S};
    border-width: 1px;
    border-color: ${COLOR.NEUTRAL_6};
    box-shadow: ${SHADOW.S};
    display: flex;
    gap: ${GAP.XS};
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - (${GLOBAL_HEADER_HEIGHT} + ${verticalOffset || 0}px));
    container-type: inline-size;

    #APPS,
    #BUILDER {
      flex: 1;
      & > span {
        display: grid;
      }
    }
  `,
  menuDark: css`
    background-color: #1c1d1f;
    #APPS {
      [data-selector='tab-display-name'] {
        color: white;
      }
    }
  `,
  collapsedMenu: css`
    width: ${CLOSED_MENU_WIDTH};
    border-radius: 0;
    border-width: 0px;
  `,
  avatarWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: none;
    }
    @container (min-width: 60px) {
      justify-content: flex-start;
      margin-left: ${GAP.M};
      p {
        display: block;
        font-size: ${FONT_SIZE.S};
        font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
        margin-left: ${GAP.S};
      }
    }
  `,
  imageContent: css`
    width: 32px;
    height: 32px;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `
};
