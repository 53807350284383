import * as Popover from '@radix-ui/react-popover';
import { styles } from './styles';

export interface ItemMenuPopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  testid?: string;
  disabled?: boolean;
}

export const ItemMenuPopover = ({ children, content, testid = 'ItemMenuPopover', disabled }: ItemMenuPopoverProps) => {
  if (disabled || !content) return children;
  return (
    <Popover.Root>
      <Popover.Trigger asChild={true} data-testid={testid}>
        <span>{children}</span>
      </Popover.Trigger>
      <Popover.Content side="right" align="start" alignOffset={-32} sideOffset={8}>
        <div css={styles.container} data-testid={`${testid}-content`}>
          {content}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
